import '@fortawesome/fontawesome-free/css/all.css';

import 'sanitize.css/sanitize.css';

import 'sanitize.css/forms.css';

import 'sanitize.css/assets.css';

import 'sanitize.css/typography.css';

import './fonts/fonts.styl';

import './index.styl';


( async () => {

  if ( process.env[ 'NODE_ENV' ] !== 'production' ) return;

  const node = document.querySelector( '#event' )!;

  const remove = () => { node.parentNode!.removeChild( node ) };

  const response = await fetch( '/.netlify/functions/data' );

  if ( ! response.ok ) return remove();

  const text = await response.text();

  if ( ! text ) return remove();

  const show = ( key: string, html: string ) => {

    node.querySelector( `.event-value[data-key="${ key }"]` )!.innerHTML = html;

  };


  const getFormatter = ( options: Partial< Intl.DateTimeFormatOptions > ) => {

    return new Intl.DateTimeFormat( 'en-us', { timeZone: 'Europe/Moscow', hour12: false, ...options } );

  };

  const dateFormatter = getFormatter( { weekday: 'long', month: 'long', day: 'numeric' } );

  const timeFormatter = getFormatter( { hour: '2-digit', minute: '2-digit' } );


  const placesMaps: { [ place: string ]: string } = {

    'Marukame at Avtozavodskaya': 'https://goo.gl/maps/ZxrPaRdbP8yXVqPp7',

  };


  const html = new DOMParser().parseFromString( text, 'text/html' );

  const card = html.querySelector( '.eventList-list > li' );

  const cardLink = card?.querySelector( 'a.eventCard--link[href]' );

  const cardTime = card?.querySelector( '.eventCardHead time' );

  const cardAddress = card?.querySelector( '.eventCardHead address' );

  if ( ! card || ! cardLink || ! cardTime || ! cardAddress ) return remove();


  const href = 'https://www.meetup.com' + cardLink.getAttribute( 'href' );

  const date = new Date( +cardTime.getAttribute( 'datetime' )! );

  const place = ( cardAddress as any ).innerText;

  const map = placesMaps[ place ];


  show( 'date',  `${ dateFormatter.format( date ) }.` );

  show( 'time',  `Starting at ${ timeFormatter.format( date ) }.` );

  show( 'place', map ? `<a href='${ map }' target='_blank' ref='noopener'>${ place }.</a>` : `${ place }.` );

  show( 'link', `<a href='${ href }' target='_blank' ref='noopener'>meetup event page.</a>` );

  node.removeAttribute( 'style' );

} )();


if ( process.env[ 'NODE_ENV' ] !== 'production' ) {

  require( './index.pug' );

}
